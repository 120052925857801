import React from 'react';
import { toast } from '@/components/ui/use-toast';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60, // 1 hours
      staleTime: 1000 * 60 * 60, // 1 hours
      refetchOnWindowFocus: false,
    },
  },

  queryCache: new QueryCache({
    onError: (error, query) => {
      toast({
        variant: 'destructive',
        title:
          query.meta?.errorMessage &&
          typeof query.meta.errorMessage === 'string'
            ? query.meta.errorMessage
            : 'Something went wrong',
        description:
          error instanceof Error ? error.message : 'An unknown error occurred',
      });
    },
  }),
});

interface AppQueryClientProviderProps {
  children: React.ReactNode;
}

export const AppQueryClientProvider: React.FC<AppQueryClientProviderProps> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
