import { Check, Plus, Search } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  NEW_SERIES_ID,
  NewComicFormData,
} from '../../../forms/newComicFormData';

// Mock data for series
const allSeries = [
  { id: 'series1', title: 'Series 1', comicCount: 5 },
  { id: 'series2', title: 'Series 2', comicCount: 10 },
  { id: 'series3', title: 'Series 3', comicCount: 11 },
  { id: 'series4', title: 'Series 4', comicCount: 5 },
  { id: 'series5', title: 'Series 5', comicCount: 10 },
  { id: 'series6', title: 'Series 6', comicCount: 11 },
  // Add more series here
];

type SeriesSelectorProps = {
  control: Control<NewComicFormData>;
};

const SeriesSelector: React.FC<SeriesSelectorProps> = ({ control }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSeries, setFilteredSeries] = useState(allSeries);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = allSeries.filter((series) =>
      series.title.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredSeries(filtered);
  }, [searchQuery]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Controller
      name="seriesId"
      control={control}
      rules={{
        validate: (value) =>
          value === NEW_SERIES_ID ||
          !!value ||
          'You need to select a series or create a new one',
      }}
      render={({ field, fieldState: { error } }) => (
        <div className="relative" ref={dropdownRef}>
          {isOpen ? (
            <div className="relative">
              <input
                ref={inputRef}
                type="text"
                placeholder="Search series..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-2 border rounded pl-8 pr-10"
              />
              <Search className="h-5 w-5 absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"></Search>
              <button
                onClick={() => setIsOpen(false)}
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <button
              onClick={() => setIsOpen(true)}
              className="w-full pl-3 pr-2 py-2 border rounded-lg text-slate-500 flex justify-between items-center bg-white"
            >
              <span>
                {field.value === NEW_SERIES_ID
                  ? 'New Series'
                  : field.value
                    ? allSeries.find((series) => series.id === field.value)
                        ?.title
                    : 'Select series...'}{' '}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
          {isOpen && (
            <div className="absolute right-1 z-10 max-w-[400px] w-full bg-white border rounded shadow-lg text-slate-600 -translate-y-full -mt-[50px]">
              <div className="max-h-[500px] overflow-auto">
                <button
                  onClick={() => {
                    field.onChange(NEW_SERIES_ID);
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-4 text-left hover:bg-slate-50 flex items-center border-b"
                >
                  <Plus className="w-5 h-5 text-slate-500 mr-1" />
                  Create New Series
                </button>
                <h3 className="px-4 py-4 text-sm font-semibold text-slate-700 bg-slate-50 border-b">
                  {searchQuery !== '' ? 'Search Results' : 'Recent Series'}
                </h3>
                {filteredSeries.length === 0 ? (
                  <p className="px-4 py-3 text-center text-sm text-gray-500">
                    No series found.
                  </p>
                ) : (
                  filteredSeries.map((series) => (
                    <button
                      key={series.id}
                      className="w-full px-6 py-3 text-left hover:bg-slate-50 flex items-start border-b last:border-b-0 "
                      onClick={() => {
                        field.onChange(series.id);
                        setSearchQuery('');
                        setIsOpen(false);
                      }}
                    >
                      <div className="flex flex-col">
                        <span className="font-medium">{series.title}</span>
                        <span className="text-sm text-slate-400">
                          {series.comicCount || 0} comics
                        </span>
                      </div>
                      {field.value === series.id && (
                        <Check className="text-blue-500 w-6 ml-1" />
                      )}
                    </button>
                  ))
                )}
              </div>
            </div>
          )}
          {error && <p className="text-red-500 mt-2">{error.message}</p>}
        </div>
      )}
    />
  );
};

export default SeriesSelector;
