import { cn } from '@/lib/utils';
import { LogoNoText } from '../../../components/icons/Logo';

const Footer = (props: { className?: string }) => {
  return (
    <footer className={cn('flex flex-col gap-2 items-center', props.className)}>
      <LogoNoText className="h-8"></LogoNoText>
      <span className="text-slate-500 text-sm">&copy; 2024 LangPanel</span>
    </footer>
  );
};

export default Footer;
