import { useEffect, useRef } from 'react';
import { Session } from '@supabase/supabase-js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/lib/supabaseClient';

// This function will be called only once to set up the listener
const setupAuthListener = (queryClient: ReturnType<typeof useQueryClient>) => {
  const {
    data: { subscription },
  } = supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_OUT') {
      queryClient.setQueryData(['session'], null);
      queryClient.invalidateQueries();
    } else {
      queryClient.setQueryData(['session'], session);
    }
  });

  return () => subscription.unsubscribe();
};

// Use a module-level variable to ensure setupAuthListener is called only once
let isListenerSetup = false;

export const useSession = () => {
  const queryClient = useQueryClient();
  const cleanupRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (!isListenerSetup) {
      cleanupRef.current = setupAuthListener(queryClient);
      isListenerSetup = true;
    }

    return () => {
      if (isListenerSetup && cleanupRef.current) {
        cleanupRef.current();
        isListenerSetup = false;
      }
    };
  }, [queryClient]);

  const { data: session } = useQuery<Session | null>({
    queryKey: ['session'],
    queryFn: async () => {
      const { data } = await supabase.auth.getSession();
      return data.session;
    },
  });

  return { session };
};
