// seriesId will be a uuid unless it is 'new_series'
// so we use this to detect if the user created a new series.
export const NEW_SERIES_ID = 'new_series';

export type NewSeriesData = {
  title: string;
  author?: string;
  description?: string;
};

export type NewComicFormData = {
  chapterNumber: number;
  chapterTitle?: string;
  files: File[];
  seriesId: string;
  newSeries?: NewSeriesData;
};
