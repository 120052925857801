import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/lib/supabaseClient';
import {
  NEW_SERIES_ID,
  NewComicFormData,
  NewSeriesData,
} from '@/forms/newComicFormData';
import { useSession } from '../useSession';
import { useCallback } from 'react';
import { toast } from '@/components/ui/use-toast';

function getThumbnail(
  images: File[],
  aspectRatio: number,
  targetWidth: number
) {
  const thumbnailWorker = new Worker(
    new URL('@/workers/createThumbnailWorker.ts', import.meta.url)
  );

  const thumbnailPromise = new Promise<Blob>((resolve, reject) => {
    if (!thumbnailWorker) {
      reject(Error('Failed to load worker for generating thumbnails.'));
    }

    thumbnailWorker.onmessage = (e) => {
      console.log('Got message from worker:', e);
      if (e.data.type === 'thumbnailGenerated') {
        resolve(e.data.thumbnail);
        thumbnailWorker.terminate();
      } else {
        console.warn('Unexpected message from worker:', e.data);
      }
    };

    thumbnailWorker.onerror = (error) => {
      console.error('Error in thumbnail worker:', error);
      reject(new Error('Failed to generate thumbnail'));
      thumbnailWorker.terminate();
    };

    thumbnailWorker.postMessage({
      type: 'generateThumbnail',
      options: {
        images: images,
        aspectRatio: aspectRatio,
        targetWidth: targetWidth,
      },
    });
  });

  return { thumbnailPromise, thumbnailWorker };
}

function createSeriesIfNecessary(
  seriesId: string,
  userId: string,
  data?: NewSeriesData
) {
  return new Promise<string>(async (resolve, reject) => {
    if (seriesId !== NEW_SERIES_ID) {
      resolve(seriesId);
      return;
    }

    if (!data) {
      reject(new Error('New series data is required'));
      return;
    }

    const { data: seriesData, error } = await supabase
      .from('series')
      .insert({
        title: data.title,
        owner_id: userId,
        author: data.author,
        description: data.description,
      })
      .select('id')
      .single();

    if (error) {
      reject(error);
      return;
    }

    resolve(seriesData.id);
  });
}

async function createComic(data: NewComicFormData, userId: string) {
  const { thumbnailPromise, thumbnailWorker } = getThumbnail(
    data.files,
    1.5,
    300
  );

  const seriesId = await createSeriesIfNecessary(
    data.seriesId,
    userId,
    data.newSeries
  )
    .then((v) => v)
    .catch((e) => {
      thumbnailWorker.terminate();
      console.error(e);
      throw new Error('Failed to create series');
    });

  // Upload images
  const imageUrls = await Promise.all(
    data.files.map(async (file, index) => {
      const fileName = `${seriesId}/${data.chapterNumber}/${index}.${file.name.split('.').pop()}`;
      const { error: uploadError } = await supabase.storage
        .from('comics')
        .upload(fileName, file);
      if (uploadError) {
        thumbnailWorker.terminate();
        throw uploadError;
      }
      return fileName;
    })
  )
    .then((v) => v)
    .catch((e) => {
      thumbnailWorker.terminate();
      console.error(e);
      throw new Error('Failed to upload some images');
    });

  // Create the comic
  const { data: newComic, error } = await supabase
    .from('comics')
    .insert({
      title: data.chapterTitle,
      chapter: data.chapterNumber,
      series_id: seriesId,
      images: imageUrls,
      height: 0, // You might want to calculate this
      width: 0, // You might want to calculate this
    })
    .single();
  if (error) {
    thumbnailWorker.terminate();
    throw new Error('Error creating new comic');
  }

  // Upload thumbnail
  console.log('waiting for thumbnail blob');
  thumbnailPromise
    .then(async (thumbnailBlob) => {
      const thumbnailFileName = `${seriesId}/${data.chapterNumber}/thumbnail.webp`;
      const { error } = await supabase.storage
        .from('comics')
        .upload(thumbnailFileName, thumbnailBlob, {
          contentType: 'image/webp',
        });
      if (error) throw Error;
    })
    .catch((e) => {
      console.error(e);
      throw new Error(
        'Error generating thumbnail. Comic will have no thumbnail.'
      );
    });

  return newComic;
}

// React Query mutation hook
export const useCreateComic = () => {
  const { session } = useSession();
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (data: NewComicFormData) => {
      if (!session) throw new Error('Must be logged in to create comic');
      return createComic(data, session.user.id);
    },
    [session]
  );
  return useMutation({
    mutationFn,
    onError: (e) =>
      toast({
        variant: 'destructive',
        title: 'An error occurred attempting to create the new comic',
        description: e.message,
      }),
    onSuccess: (_data) => {
      toast({
        variant: 'default',
        title: 'Comic uploaded successfully',
      });
      queryClient.invalidateQueries({ queryKey: ['series'] });
      queryClient.invalidateQueries({ queryKey: ['comics'] });
    },
  });
};
