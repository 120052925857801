import { createClient } from '@supabase/supabase-js';
import { Database } from '@/lib/database.types';

if (!import.meta.env.VITE_SUPABASE_URL) {
  throw new Error('VITE_SUPABASE_URL is not defined in environment variables');
}
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;

if (!import.meta.env.VITE_SUPABASE_ANON_KEY) {
  throw new Error(
    'VITE_SUPABASE_ANON_KEY is not defined in environment variables'
  );
}
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);
