import { useForm, Controller } from 'react-hook-form';
import ComicUpload from '@/routes/dashboard/components/ComicUpload';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import Footer from '../components/Footer';
import {
  NEW_SERIES_ID,
  NewComicFormData,
} from '../../../forms/newComicFormData';
import { ExpandableSeriesSection } from './ExpandableSeriesSection';
import { useCreateComic } from '@/hooks/comics/useCreateComic';

const NewComic = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<NewComicFormData>({
    defaultValues: {
      chapterNumber: 1,
      chapterTitle: '',
      files: [],
      seriesId: NEW_SERIES_ID,
      newSeries: {
        title: 'New Series Default',
        author: '',
        description: '',
      },
    },
  });

  const createComicMutation = useCreateComic();

  const onSubmit = (data: NewComicFormData) => {
    console.log(data);
    createComicMutation.mutate(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="my-14 container mx-auto px-8 flex flex-col gap-10"
    >
      <h1 className="text-3xl text-slate-600 font-semibold">
        Upload New Comic
      </h1>

      <div className="p-8 bg-slate-50 rounded-lg flex flex-col gap-6 shadow-sm border border-slate-200">
        <div className="flex flex-col">
          <h2 className="text-xl text-slate-600 font-semibold">Step 1</h2>
          <h3 className="text-sm text-slate-400 font-bold">
            Basic Information
          </h3>
        </div>
        <div className="flex gap-4">
          <Controller
            name="chapterNumber"
            control={control}
            rules={{ required: 'Chapter number is required' }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Chapter Number*"
                className="basis-[240px]"
              />
            )}
          />
          <Controller
            name="chapterTitle"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Chapter Title" />
            )}
          />
        </div>
        {errors.chapterNumber && (
          <p className="text-red-500">{errors.chapterNumber.message}</p>
        )}
      </div>

      <div className="p-8 bg-slate-50 rounded-lg flex flex-col gap-6 shadow-sm border border-slate-200">
        <div className="flex flex-col">
          <h2 className="text-xl text-slate-600 font-semibold">Step 2</h2>
          <h3 className="text-sm text-slate-400 font-bold">Upload Images</h3>
        </div>
        <Controller
          name="files"
          control={control}
          rules={{ required: 'At least one image is required' }}
          render={({ field }) => (
            <ComicUpload
              onChange={(f) => field.onChange(f ?? [])}
              value={field.value}
              className={`${
                field.value.length === 0
                  ? 'h-[300px]'
                  : 'min-h-[300px] max-h-[600px]'
              } shadow-none p-0`}
            />
          )}
        />
        {errors.files && <p className="text-red-500">{errors.files.message}</p>}
      </div>

      <ExpandableSeriesSection
        control={control}
        errors={errors}
        watch={watch}
      />

      <div className="ml-auto">
        <Button type="submit" size={'lg'} variant={'default'}>
          Upload
        </Button>
      </div>
      <div className="h-[1px] bg-slate-100"></div>
      <Footer />
    </form>
  );
};

export default NewComic;
